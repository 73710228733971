import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import "./App.css";
import { getFromSession, validateSession } from "./helpers/storageHandler";
import { useEffect } from "react";
import { initialFetch } from "./redux/actions/auth";
import NewClient from "./pages/ClientInsert";
import ListClient from "./pages/ClientList";
import DetailClient from "./pages/ClientDetail";
import Invoicing from "./pages/Invoicing";
import InvoiceInsert from "./pages/InvoiceInsert";
import CashingReports from "./pages/CashingReports";
import CashingAnalytics from "./pages/CashingAnalytics";

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

const AppRouter = () => {
  const dispatch = useDispatch();
  const jwt = getFromSession("jwt");

  useEffect(() => {
    if (jwt) {
      dispatch(initialFetch(jwt));
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={validateSession() ? "/inicio" : "/login"} />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/inicio" element={<NewClient />} />
        <Route exact path="/clientes" element={<ListClient />} />
        <Route exact path="/facturacion" element={<Invoicing />} />
        <Route exact path="/detalle-cliente/:id" element={<DetailClient />} />
        <Route exact path="/facturar" element={<InvoiceInsert />} />
        <Route exact path="/reportes-cobranza" element={<CashingReports />} />
        <Route
          exact
          path="/analiticos-cobranza"
          element={<CashingAnalytics />}
        />
      </Routes>
    </Router>
  );
};

export default App;
