import React, { useState } from "react";
import logo from "../css/img/LogoSES.svg";
import {
  DollarSign,
  PersonStanding,
  ShieldAlert,
  Power,
  Banknote,
} from "lucide-react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "../App.css";

const Menu = () => {
  const [anchorEl, setAnchorEl] = useState();
  const [subItems, setSubItems] = useState([]);
  const handleClick = (ix) => (event) => {
    setSubItems(menuItems[ix].subItems);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const menuItems = [
    {
      name: "Cobranza & CRM",
      icon: (
        <DollarSign
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      subItems: [
        {
          name: "Alta de cliente",
          to: "/inicio",
        },
        {
          name: "Seguimiento de Clientes",
          to: "/clientes",
        },
        {
          name: "Reporte de cobranza",
          to: "/reportes-cobranza",
        },
        {
          name: "Analíticos de cobranza",
          to: "/analiticos-cobranza",
        },
      ],
    },
    {
      name: "Facturación",
      icon: (
        <Banknote
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      subItems: [
        {
          name: "Facturación",
          to: "/facturacion",
        },
        {
          name: "Factura general",
          to: "/facturar",
        },
      ],
    },
    {
      name: "RH y Nómina",
      icon: (
        <PersonStanding
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      subItems: [],
    },
    {
      name: "Administrar",
      icon: (
        <ShieldAlert
          color="#2A2F99"
          size={22}
          className="ml-4 mr-2 relative float-left"
        />
      ),
      subItems: [],
    },
  ];
  return (
    <>
      <div className="sideBar">
        <img src={logo} className="w-2/3 mx-auto my-8" />
        <div className="mt-2 menuTop">
          {menuItems.map((it, ix) => (
            <>
              <div
                className="relative float-left my-4 w-11/12 h-8 menuItem"
                onClick={handleClick(ix)}
              >
                {it.icon}
                <span className="text-sesBlue relative float-left cursor-pointer mt-0 font-semibold">
                  {it.name}
                </span>
              </div>
            </>
          ))}
          {subItems.length > 0 && (
            <Popover
              id={1}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.5rem",
                }}
              >
                {subItems.map((sIt) => (
                  <Button
                    className="itemSubmenu"
                    onClick={() => navigate(sIt.to)}
                  >
                    {sIt.name}
                  </Button>
                ))}
              </div>
            </Popover>
          )}
        </div>

        <div className="relative top-16">
          <div className="relative float-left my-4 w-11/12 h-8">
            <Power
              color="#2A2F99"
              size={22}
              className="ml-4 mr-2 relative float-left"
            />
            <span className="text-sesBlue relative float-left cursor-pointer mt-0 font-semibold">
              Cerrar sesión
            </span>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Menu;
