import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../components/Button";
import DgForm from "../../components/DgForm";
import { useForm } from "../../helpers/useForm";
import { useSelector } from "react-redux";
import { noop, parseCat } from "../../helpers/utils";
import PaymentLabel from "../../components/PaymentLabel";
import { Request } from "../../helpers/api";
import Swal from "sweetalert2";

const InvoicePaymentStatus = ({
  isOpen,
  toggle,
  data: paymentData = {},
  fetchData = noop,
}) => {
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...paymentData,
    idCatStatusPaymentService: 3,
    comments: "",
  });
  const { CatStatusPaymentService, bank: CatBanks } = useSelector(
    (s) => s.catalogues
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    const { idBank } = data;
    const res = await Request(
      "/service/statuspayment/" + paymentData.idService,
      { idCatStatusPaymentService: 3, idBank },
      "PUT"
    );
    if (res.ok) {
      Swal.fire("Actualización exitosa", "", "success").then(() => {
        fetchData();
        toggle();
      });
    }
  };
  useEffect(() => {
    setAllData({ ...paymentData, comments: "" });
  }, [paymentData]);
  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={toggle}>Estatus de pago</ModalHeader>
      <ModalBody>
        <div className="m-auto mx-2">
          <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
            ESTATUS ACTUAL
          </h2>
          <PaymentLabel
            text={paymentData.catStatusPaymentService}
            id={paymentData.idCatStatusPaymentService}
          />
          <DgForm
            data={data}
            onChange={onDataChange}
            onSubmit={onSubmit}
            groups={[
              {
                title: "CAMBIAR ESTATUS DE PAGO",
                fields: [
                  {
                    type: "select",
                    label: "Nuevo estatus de pago",
                    required: true,
                    iconName: "DollarSign",
                    name: "idCatStatusPaymentService",
                    options: parseCat(
                      CatStatusPaymentService,
                      "name",
                      "idCatStatusPaymentService"
                    ),
                    disabled: true,
                    value: 3,
                  },
                  {
                    type: "select",
                    label: "Banco",
                    iconName: "Landmark",
                    required: true,
                    name: "idBank",
                    options: [
                      { label: "Elige un banco", value: "" },
                      ...parseCat(CatBanks, "name", "idBank"),
                    ],
                    value: data.idBank,
                  },
                  {
                    type: "textarea",
                    label: "Comentarios",
                    required: false,
                    name: "comments",
                    value: data.comments,
                    iconName: "FormInput",
                  },
                ],
              },
            ]}
            classNameGrid="grid-cols-1"
          >
            <div className="grid grid-cols-2 gap-3">
              <Button
                onClick={() => {
                  toggle();
                }}
              >
                Cancelar
              </Button>
              <Button type="submit">Aceptar</Button>
            </div>
          </DgForm>
        </div>
      </ModalBody>
    </Modal>
  );
};

InvoicePaymentStatus.propTypes = {};

export default InvoicePaymentStatus;
