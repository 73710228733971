import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import DgInput from "../../components/DgInput";
import { Request } from "../../helpers/api";
import ClientCard from "../../components/ClientCard";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import { downloadBlobFile } from "../../helpers/utils";

const AccountState = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState("");
  const fetchData = useCallback(async () => {
    const { ok, data } = await Request("/client");
    if (ok) {
      const parsedClients = data.map((c) => ({ ...c, selected: false }));
      setAllData(parsedClients);
      setData(parsedClients);
    }
  }, []);
  const handleTapCard = (ix) => () => {
    const client = data[ix];
    const newClients = [...data].map((c) => ({ ...c, selected: false }));
    newClients[ix].selected = !client.selected;
    setAllData(newClients);
    setData(newClients);
  };
  const handleSubmit = async () => {
    const clientSelected = data.find((c) => c.selected);
    if (clientSelected) {
      const { idClient } = clientSelected;
      const res = await Request(
        "/report/accountStatus/" + idClient,
        null,
        "GET",
        true,
        false,
        true
      );
      if (res) {
        downloadBlobFile(
          res,
          `Estado de Cuenta - ${clientSelected.name.toUpperCase()}.xlsx`
        );
      }
    } else {
      Swal.fire(
        "¡Espera!",
        "Debes seleccionar un cliente antes de generar el reporte",
        "warning"
      );
    }
  };
  const handleSearch = (field) => (e) => {
    const { value } = e.target;
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields = field === "all" ? ["name", "rfc"] : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  useState(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div>
      <div className="grid gap-3" style={{ gridTemplateColumns: "80% 20%" }}>
        <DgInput
          label="Buscar Cliente"
          placeholder="Buscar ..."
          iconName="Search"
          name="search"
          onChange={handleSearch("all")}
          value={search}
        />
        <Button style={{ marginTop: "40px" }} onClick={handleSubmit}>
          Generar Reporte
        </Button>
      </div>
      <div className="h-5/6 overflow-y-auto">
        {data.map((c, ix) => (
          <ClientCard
            client={{ ...c, ix }}
            selectable={true}
            onTap={handleTapCard}
          />
        ))}
      </div>
    </div>
  );
};

AccountState.propTypes = {};

export default AccountState;
