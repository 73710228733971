import React from "react";
import "./Button.css";

const Button = ({
  children,
  onClick,
  type = "button",
  style = [],
  disabled = false,
}) => {
  return (
    <div className="mx-auto w-full mt-8">
      <button
        {...{
          style,
          onClick,
          type,
          disabled,
        }}
        className="cssButton bg-sesBlue text-white py-3 px-8 w-full mx-auto text-center"
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
