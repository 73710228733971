import { googleGeoBaseApi, googleGeoKey } from "./consts";

export const noop = () => {};

export const parseCat = (arr, strTxt, strVal) => {
  if (arr) {
    return arr.map((n) => {
      return {
        label: n[strTxt],
        value: n[strVal],
      };
    });
  }
  return [];
};

export const parseObjectDate = (date, format = 1) => {
  const monthsEs = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let r;
  switch (format) {
    case 1: // DD/MM/YYYY
      r = [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
      break;
    case 2: // Dia Mes YY
      r = [
        date.getDate().toString().padStart(2, "0"),
        monthsEs[date.getMonth()],
        date.getFullYear().toString().substring(2, 4) + "'",
      ].join(" ");
      break;

    case 3: // DD/MM/YYYY
      r = [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
      break;
    case 4: // YYYY-MM-DD
      r = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
      ].join("-");
      break;
    default:
  }
  return r;
};

export const downloadBlobFile = (blobRes, fileName = "untitled.txt") => {
  if (blobRes) {
    const url = window.URL.createObjectURL(new Blob([blobRes]));
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = url;
    link.click();
  }
};

export const formatPhone = (phone) => {
  const clearValue = phone.replaceAll("-", "");
  const formattedValue =
    clearValue.length === 10
      ? [
          clearValue.substring(0, 2),
          clearValue.substring(2, 6),
          clearValue.substring(6, 10),
        ].join("-")
      : phone;
  return formattedValue;
};

export const getGeolocationData = async (zipCode, country) => {
  if (zipCode.length > 4) {
    const res = await (
      await fetch(
        `${googleGeoBaseApi}?address=${zipCode},${country}&key=${googleGeoKey}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
    ).json();
    if (res.status === "OK") {
      const { address_components = [], postcode_localities: localities = [] } =
        res.results[0];
      const state = address_components.find((ac) =>
        ac.types.find((t) => t === "administrative_area_level_1")
      );
      const municipality = address_components.find((ac) =>
        ac.types.find((t) => /sublocality/.test(t))
      );
      return {
        address_components,
        localities: localities.map((l) => ({ label: l, value: l })),
        state: state ? state.long_name : null,
        municipality: municipality ? municipality.long_name : null,
      };
    }
  }
  return { address_components: [], localities: [], state: null, suburb: null };
};

export const searchInObjectArray = (searchValue, searchArray, searchFields) => {
  const searchLower = searchValue.toLowerCase();
  const filteredData = [];
  searchArray.forEach((d) => {
    let found = false;
    searchFields.forEach((k) => {
      const val = d[k] ? d[k].toLowerCase() : "";
      if (val.indexOf(searchLower) !== -1) {
        found = true;
      }
    });
    if (found) {
      filteredData.push(d);
    }
  });
  return filteredData;
};

export const getColorPayment = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#F88F14";
      break;
    case 2:
      color = "#C82B2B";
      break;
    case 3:
      color = "#0CBE13";
      break;
    default:
      color = "#000";
  }
  return color;
};

export const getColorInvoice = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#F88F14";
      break;
    case 2:
      color = "#30B6FF";
      break;
    case 3:
      color = "#30B6FF";
      break;
    case 4:
      color = "#C82B2B";
      break;
    default:
      color = "#000";
  }
  return color;
};
