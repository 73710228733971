export const clientSchema = {
  companyName: "",
  rfc: "",
  phone: "",
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  postalCode: "",
  municipality: "",
  state: "",
  country: "MX",
  idCatCfdiUsage: "",
  idCatPaymentMethod: "",
  idCatRegime: "",
  clientType: "NONFISCAL",
  counterName: "",
  counterEmail: "",
  administratorName: "",
  administratorEmail: "",
  administratorPhone: "",
};

export const clientDetailSchema = {
  ...clientSchema,
  services: [],
  documents: [],
  comments: [],
  regime: [],
};

export const serviceSchema = {
  idClient: "",
  idCatRegion: "",
  streetService: "",
  internalNumberService: "",
  outdoorNumberService: "",
  suburbService: "",
  postalCodeService: "",
  name: "",
  municipalityService: "",
  stateService: "",
  countryService: "MX",
  idFiscalPeriod: "",
  paymentPeriod: "",
  serviceConcepts: [],
};

export const conceptSchema = {
  idService: "",
  idCatServiceConcept: "",
  quantity: "",
  description: "",
  price: "",
  absenceDiscount: 0,
};
export const commentSchema = {
  idComment: 0,
  idClient: 0,
  idUser: "",
  title: "",
  description: "",
};

export const invoiceSchema = {
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  postalCode: "",
  name: "",
  rfc: "",
  municipality: "",
  state: "",
  country: "MX",
  amount: "",
  idCatCfdiUsage: "",
  idCatPaymentMethod: "",
  idCatRegime: "",
};

export const invoicePaymentSchema = {
  paymentStatus: "",
  idCatPaymentStatus: "",
  idBank: "",
  comments: "",
};
