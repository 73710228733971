import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FileInput } from "lucide-react";

const InvoiceDetails = ({ isOpen, toggle, data = {} }) => {
  const { urlInvoice, invoice, nameClient, urlRemission } = data;
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        <FileInput color={"#565656"} size={40} className="inline-block" />{" "}
        Factura: {invoice}
      </ModalHeader>
      <ModalBody>
        <div className="grid grid-cols-1">
          <embed
            className="w-full"
            height={700}
            src={urlInvoice || urlRemission}
            type="application/pdf"
          />
        </div>
      </ModalBody>
      {/*<ModalFooter>
        <div className="flex flex-row-reverse">
          <Button
            onClick={() => {
              toggle();
            }}
          >
            Aceptar
          </Button>
        </div>
        </ModalFooter>*/}
    </Modal>
  );
};

InvoiceDetails.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  invice: PropTypes.object,
};

export default InvoiceDetails;
