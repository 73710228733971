import React, { useCallback, useEffect, useState } from "react";
import DgInput from "../components/DgInput";
import Skinpage from "../components/Skinpage";
import "../App.css";
import "../App.jsx";
import Button from "../components/Button";
import Swal from "sweetalert2";
import DgTable from "../components/DgTable";
import { getColumnsClients } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useNavigate } from "react-router-dom";
import ModalPlantillaServices from "./modals/ModalPlantillaServices";
import { downloadBlobFile } from "../helpers/utils";
import { useForm } from "../helpers/useForm";

const ListClient = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useForm({
    clientType: "",
    amount: "",
  });
  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const funcs = {
    service: () => {},
    details: ({ idClient }) => {
      navigate("/detalle-cliente/" + idClient);
    },
    serviceDetails: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    mail: (row) => {
      Swal.fire({
        title: "Reporte de servicio",
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Enviar reporte",
        denyButtonText: `Descargar reporte`,
        confirmButtonColor: "#7066e0",
        denyButtonColor: "#7066e0",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Request(
            "/cron/sendDetailServiceEmail?idService=" + row.idService,
            data,
            "GET"
          );
          if (res.ok) {
            Swal.fire("El reporte se envio por correo", "", "success");
            // Swal.fire("Modificación de plantilla exitosaa", "", "success").then(
            //   () => {}
            // );
          }
        } else if (result.isDenied) {
          const res = await Request(
            "/report/serviceDetail/" + row.idService,
            null,
            "GET",
            true,
            false,
            true
          );
          if (res) {
            downloadBlobFile(res, `Detalle de Servicio.xlsx`);
          }
        }
      });
    },
  };
  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields = field === "all" ? ["name", "rfc", "street"] : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const fetchData = useCallback(async (filters) => {
    const filterStr = Object.keys(filters)
      .map((k) => `${k}=${filters[k]}`)
      .join("&");
    const res = await Request(`/client/service?${filterStr}`);
    console.log("res", res);
    //const resC = await Request(`/client?${filterStr}`);
    if (res.ok) {
      let nonServiceClients = [];
      let clientsOb = {};
      const serviceClients = res.data.map((d) => d.idClient);
      // if (resC.ok) {
      //   nonServiceClients = resC.data.filter((c) => !serviceClients.includes(c.idClient));
      // }

      const allClients = res.data;
      setData(allClients);
      setAllData(allClients);
    }
  }, []);
  useEffect(() => {
    fetchData({});
  }, [fetchData]);
  return (
    <Skinpage pageTitle={"Seguimiento de Clientes"}>
      <div className="grid grid-cols-4 gap-5" style={{ alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar cliente"
          iconName="Search"
        />
        <DgInput
          type="select"
          label="Saldo"
          options={[
            {
              label: "Todos",
              value: "",
            },
            {
              label: "Al corriente",
              value: "CURRENT",
            },
            {
              label: "Vencido",
              value: "DEUBT",
            },
          ]}
          onChange={setFilters}
          name="amount"
          value={filters.amount}
          iconName="DollarSign"
        />
        <DgInput
          type="select"
          label="Tipo de cliente"
          options={[
            {
              label: "Todos",
              value: "",
            },
            {
              label: "Fiscal",
              value: "FISCAL",
            },
            {
              label: "No Fiscal",
              value: "NONFISCAL",
            },
          ]}
          onChange={setFilters}
          name="clientType"
          value={filters.clientType}
          iconName="Users"
        />
        <Button
          style={{ margin: 0 }}
          onClick={() => {
            navigate("/inicio");
          }}
        >
          Nuevo Cliente
        </Button>
      </div>
      <DgTable data={data} columnsDef={getColumnsClients(funcs)} />
      <ModalPlantillaServices
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
        singleData={singleData}
      />
    </Skinpage>
  );
};

export default ListClient;
