import * as React from "react";
import DataTable from "react-data-table-component";

const customStyles = {
  rows: {
    style: {
      minHeight: "3rem", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "0.8rem",
      fontWeight: 600,
      padding: "1% 1%",
      backgroundColor: "#2A2F99",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "1%",
      paddingRight: "1%",
      fontSize: "0.9rem",
      color: "#2A2F99",
    },
  },
};

const DgTable = ({ columnsDef, data, pagination = 10 }) => {
  return (
    <div
      className="containerTable"
      style={{ width: "100%", margin: "20px auto" }}
    >
      <DataTable
        pagination={pagination}
        columns={columnsDef}
        responsive={true}
        highlightOnHover={true}
        customStyles={customStyles}
        data={data}
        striped={true}
        noDataComponent={
          <p style={{ fontStyle: "italic", fontSize: "1.2rem" }}>
            No hay registros para mostrar
          </p>
        }
      />
    </div>
  );
};

export default DgTable;
