import React, { useCallback, useEffect, useState } from "react";
import DgInput from "../components/DgInput";
import Skinpage from "../components/Skinpage";
import Button from "../components/Button";
import DgTable from "../components/DgTable";
import InvoicePaymentStatus from "./modals/InvoicePaymentStatus";
import InvoiceDocumentDetails from "./modals/InvoiceDoumentDetails";
import InvoiceServiceDetails from "./modals/InvoiceServiceDetails";
import { useForm } from "../helpers/useForm";
import { getColumnsInvoices } from "../helpers/datatableHelpers";
import { Request } from "../helpers/api";
import { useNavigate } from "react-router-dom";
import { parseCat } from "../helpers/utils";
import { useSelector } from "react-redux";
import "../App.css";
import InvoiceDetails from "./modals/InvoiceDetails";

const Invoicing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [modalOpenPayment, setModalOpenPayment] = useState(false);
  const [modalOpenDocs, setModalOpenDocs] = useState(false);
  const [modalOpenServ, setModalOpenServ] = useState(false);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);
  const { CatStatusPaymentService, CatStatusInvoiceService } = useSelector(
    (s) => s.catalogues
  );
  const funcs = {
    documents: (row) => () => {
      setSingleData(row);
      setModalOpenDocs(true);
    },
    details:
      ({ idClient }) =>
      () => {
        navigate("/detalle-cliente/" + idClient);
      },
    edit: (row) => () => {
      setSingleData(row);
      setModalOpenServ(true);
    },
    payment: (row) => () => {
      setSingleData(row);
      setModalOpenPayment(true);
    },
    onSelectCheck: (row) => () => {
      const { idService, idClient } = row;
      let ix;
      data.find(({ idService: idS, idClient: idC }, i) => {
        ix = i;
        return idS === idService && idC === idClient;
      });
      const newData = [...data];
      const client = newData[ix];
      client.selected = !client.selected;
      newData[ix] = client;
      setData([...newData]);
    },
    invoiceDetails: (row) => () => {
      setSingleData(row);
      setModalOpenInvoice(true);
    },
  };
  const [filters, setFilters] = useForm({
    idCatStatusPaymentService: "",
    idCatStatusInvoiceService: "",
    idFiscalPeriod: "",
  });
  const fetchFiscalPeriods = useCallback(async () => {
    const { ok, data } = await Request(`/user/fiscalPeriods?limit=4&all=false`);
    if (ok) {
      setFiscalPeriodList(data);
      const firstPeriod = data[0].idFiscalPeriod;
      setFilters({ target: { name: "idFiscalPeriod", value: firstPeriod } });
    }
  }, []);
  const fetchData = useCallback(async (filters) => {
    if (filters.idFiscalPeriod !== "") {
      const filterStr = Object.keys(filters)
        .map((k) => `${k}=${filters[k]}`)
        .join("&");
      const res = await Request(`/invoice/service?${filterStr}`);
      if (res.ok) {
        const invoiceList = res.data.map((f) => ({ ...f, selected: false }));
        setData(invoiceList);
      }
    }
  }, []);
  useEffect(() => {
    fetchFiscalPeriods();
  }, [fetchFiscalPeriods]);
  useEffect(() => {
    fetchData(filters);
  }, [filters]);
  return (
    <Skinpage pageTitle={"Facturación"}>
      <div className="grid grid-cols-4 gap-5" style={{ alignItems: "end" }}>
        <DgInput
          type="select"
          label="Estatus Facturación"
          options={[
            { value: "", label: "Todos" },
            ...parseCat(
              CatStatusInvoiceService,
              "name",
              "idCatStatusInvoiceService"
            ),
          ]}
          onChange={setFilters}
          name="idCatStatusInvoiceService"
          value={filters.idCatStatusInvoiceService}
          placeholder="Seleccionar estatus"
          iconName="Files"
        />
        <DgInput
          type="select"
          label="Estatus Pago"
          options={[
            { value: "", label: "Todos" },
            ...parseCat(
              CatStatusPaymentService,
              "name",
              "idCatStatusPaymentService"
            ),
          ]}
          onChange={setFilters}
          name="idCatStatusPaymentService"
          value={filters.idCatStatusPaymentService}
          placeholder="Seleccionar estatus"
          iconName="Files"
        />
        <DgInput
          type="select"
          label="Periodo"
          onChange={setFilters}
          options={[...parseCat(fiscalPeriodList, "name", "idFiscalPeriod")]}
          name="idFiscalPeriod"
          value={filters.idFiscalPeriod}
          placeholder="Seleccionar periodo"
          iconName="Calendar"
        />
        <Button
          onClick={() => {
            const services = data.filter((d) => d.selected);
            services.length > 0
              ? navigate("/facturar", { state: services })
              : navigate("/facturar");
          }}
        >
          Facturar
        </Button>
      </div>
      <DgTable data={data} columnsDef={getColumnsInvoices(funcs)} />
      <InvoicePaymentStatus
        isOpen={modalOpenPayment}
        toggle={() => {
          setModalOpenPayment(!modalOpenPayment);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <InvoiceDocumentDetails
        isOpen={modalOpenDocs}
        toggle={() => {
          setModalOpenDocs(!modalOpenDocs);
        }}
        data={singleData}
      />
      <InvoiceServiceDetails
        isOpen={modalOpenServ}
        toggle={() => {
          setModalOpenServ(!modalOpenServ);
        }}
        data={singleData}
      />
      <InvoiceDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={singleData}
      />
    </Skinpage>
  );
};

export default Invoicing;
