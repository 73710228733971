import React from "react";
import { getColorInvoice, getColorPayment, parseObjectDate } from "./utils";
import {
  Wallet,
  Edit,
  Paperclip,
  Mail,
  Eye,
  Banknote,
  Contact,
} from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "@mui/material";
import PaymentLabel from "../components/PaymentLabel";

const customCell = (
  selector,
  alignment = "left",
  renderText = (text) => {
    return text;
  }
) => {
  return (row) => (
    <div style={{ textAlign: alignment, width: "100%" }}>
      {renderText(row[selector])}
    </div>
  );
};

export const getColumnsClients = (funcs) => [
  {
    name: "NO.",
    center: true,
    width: "5%",
    key: "idClient",
    cell: customCell("idClient", "center"),
  },
  {
    name: "NOMBRE COMERCIAL",
    width: "16%",
    center: true,
    key: "tradeName",
    cell: customCell("tradeName", "center"),
  },
  {
    name: "RAZÓN SOCIAL",
    width: "12%",
    center: true,
    key: "businessName",
    cell: customCell("businessName"),
  },
  {
    name: "RFC",
    width: "14%",
    center: true,
    key: "rfc",
    cell: customCell("rfc", "center"),
  },
  {
    name: "DIRECCIÓN",
    width: "14%",
    center: true,
    key: "address",
    cell: customCell("address"),
  },
  {
    name: "ES FISCAL",
    width: "12%",
    center: true,
    key: "conceptsServices",
    cell: (row) => {
      return row.clientType === "FISCAL" ? (
        <FontAwesomeIcon icon={faCheck} size={"2x"} color="green" />
      ) : (
        <FontAwesomeIcon icon={faTimes} size={"2x"} color="orange" />
      );
    },
  },
  {
    name: "FECHA DE PAGO",
    width: "10%",
    center: true,
    key: "estimatedPaymentDate",
    //cell: customCell("estimatedPaymentDate", "left", (t) => (t ? parseObjectDate(new Date(t), 3) : '' )),
    cell: (row) => (
      <>
        {row.balance != null ? (
          row.idCatStatusPaymentService == 2 ? (
            <span className={`text-sesRed `} color="#d9534f" size={20}>
              {parseObjectDate(new Date(row.estimatedPaymentDate), 3)}
            </span>
          ) : (
            <span color="#d9534f" size={20}>
              {parseObjectDate(new Date(row.estimatedPaymentDate), 3)}
            </span>
          )
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    name: "SALDO",
    width: "8%",
    center: true,
    key: "balance",
    //cell: customCell("balance", "left", (t) => (t ? "$ "+t : "$ -")),
    cell: (row) => (
      <>
        {row.balance != null ? (
          row.idCatStatusPaymentService == 2 ? (
            <span className={`text-sesRed `} color="#d9534f" size={20}>
              $ {row.balance}
            </span>
          ) : (
            <span color="#d9534f" size={20}>
              $ {row.balance}
            </span>
          )
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    name: "ACCIONES",
    width: "9%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {/*<ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.service(row);
          }}
          icon={faMoneyBill}
        />
         <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.mail(row);
          }}
          icon={faMailBulk}
        />*/}
        <Eye
          color="#2A2F99"
          size={20}
          className="m-auto"
          onClick={() => {
            funcs.details(row);
          }}
          style={{ cursor: "pointer" }}
        />
        {/* <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.details(row);
          }}
          icon={AlertCircle}
        /> */}
        {row.idService && (
          <Wallet
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.serviceDetails(row);
            }}
          />
          // <ButtonIcon
          //   className="px-1"
          //   onClick={() => {
          //     funcs.serviceDetails(row);
          //   }}
          //   icon={faFileLines}
          // />
        )}
        {row.idService && (
          <Mail
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.mail(row);
            }}
          />
        )}
      </>
    ),
  },
];

export const getColumnsInvoices = (funcs) => [
  {
    name: <></>,
    width: "5%",
    center: true,
    cell: (row) => {
      return (
        ![2, 3].includes(row.idCatStatusInvoiceService) && (
          <Checkbox value={row.selected} onChange={funcs.onSelectCheck(row)} />
        )
      );
    },
  },
  {
    name: "NOMBRE O RAZÓN SOCIAL",
    width: "16%",
    center: true,
    key: "nameClient",
    cell: customCell("nameClient"),
  },
  {
    name: "NOMBRE SERVICIO",
    width: "12%",
    center: true,
    key: "nameService",
    cell: customCell("nameService"),
  },
  {
    name: "CLIENTE",
    width: "10%",
    center: true,
    key: "clientType",
    cell: customCell("clientType", "center", (t) =>
      t === "NONFISCAL" ? "NO FISCAL" : "FISCAL"
    ),
  },
  {
    name: "NO. DE FACTURA",
    width: "10%",
    center: true,
    key: "invoice",
    cell: customCell("invoice", "center", (t) => (t ? t : "-")),
  },
  {
    name: "IMPORTE",
    width: "10%",
    center: true,
    key: "amount",
    cell: customCell("amount", "center", (t) => "$ " + (t ? t : "-")),
  },
  {
    name: "ESTATUS FACT",
    width: "10%",
    center: true,
    key: "catStatusInvoiceService",
    cell: (row) => {
      return (
        <PaymentLabel
          text={row.catStatusInvoiceService}
          id={row.idCatStatusInvoiceService}
          parserColor={getColorInvoice}
        />
      );
    },
  },
  {
    name: "ESTATUS PAGO",
    width: "10%",
    center: true,
    key: "catStatusPaymentService",
    cell: (row) => {
      return (
        <PaymentLabel
          text={row.catStatusPaymentService}
          id={row.idCatStatusPaymentService}
          parserColor={getColorPayment}
        />
      );
    },
  },
  {
    name: "ACCIONES",
    width: "16%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <Contact
          color="#2A2F99"
          size={20}
          className="m-auto action-table"
          style={{ cursor: "pointer" }}
          onClick={funcs.details(row)}
        />
        <Paperclip
          color="#2A2F99"
          size={20}
          className="m-auto action-table"
          style={{ cursor: "pointer" }}
          onClick={funcs.documents(row)}
        />
        {[1, 4].includes(row.idCatStatusInvoiceService) && (
          <Edit
            color="#2A2F99"
            size={20}
            className="m-auto action-table"
            style={{ cursor: "pointer" }}
            onClick={funcs.edit(row)}
          />
        )}
        {[2, 3].includes(row.idCatStatusInvoiceService) && (
          <Eye
            color="#2A2F99"
            size={20}
            className="m-auto action-table"
            onClick={funcs.invoiceDetails(row)}
            style={{ cursor: "pointer" }}
          />
        )}
        {row.idCatStatusPaymentService < 3 && (
          <Banknote
            color="#2A2F99"
            size={20}
            className="m-auto action-table"
            style={{ cursor: "pointer" }}
            onClick={funcs.payment(row)}
          />
        )}
      </>
    ),
  },
];
