import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../../components/Button";
import { FileInput } from "lucide-react";
import Title from "../../components/Title";
import DgInput from "../../components/DgInput";
import DocumentCard from "../../components/DocumentCard";
import { searchInObjectArray } from "../../helpers/utils";

const InvoiceDocumentDetails = ({ isOpen, toggle, documents = [] }) => {
  const [search, setSearch] = useState("");
  const [filteredDocs, setFilteredDocs] = useState(documents);
  const [selDoc, setSelDoc] = useState({});
  const handleSearch = (e) => {
    const { value, name } = e.target;
    setSearch(value);
    const results = searchInObjectArray(value, documents, ["url", "name"]);
    setFilteredDocs(results);
  };
  const handleDelete = (document, ix) => {};
  const handleDetail = (document) => {
    setSelDoc(document);
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        <FileInput color={"#565656"} size={50} className="inline-block" />{" "}
        ADJUNTAR ARCHIVOS
      </ModalHeader>
      <ModalBody>
        <div className="grid grid-cols-2">
          <div>
            <Title>Lista de archivos</Title>
            <DgInput
              type="text"
              placeholder="Buscar"
              iconName="Search"
              name="serach"
              value={search}
              onChange={handleSearch}
            />
            {filteredDocs.map((d, ix) => (
              <DocumentCard
                document={d}
                handlers={{ handleDelete, handleDetail }}
                ix={ix}
              />
            ))}
          </div>
          <embed
            className="w-full"
            height={700}
            src={selDoc.url}
            type="application/pdf"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex flex-row-reverse">
          <Button
            onClick={() => {
              toggle();
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

InvoiceDocumentDetails.propTypes = {};

export default InvoiceDocumentDetails;
