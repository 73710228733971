import React, { useEffect, useState } from "react";
import DgForm from "../components/DgForm";
import Skinpage from "../components/Skinpage";
import Swal from "sweetalert2";
import Button from "../components/Button";
import { useForm } from "../helpers/useForm";
import { clientSchema } from "../helpers/schemas";
import { Request } from "../helpers/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientInputs } from "../helpers/formUtils";
import { formatPhone, getGeolocationData } from "../helpers/utils";
import "../App.css";

const NewClient = () => {
  // Hooks and vars
  const navigate = useNavigate();
  const { CatCfdi, CatRegime, CatPaymentWay } = useSelector(
    (s) => s.catalogues
  );
  const [suburbs, setSuburbs] = useState([]);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientSchema,
  });

  // Logic
  const handleZipChange = async (value) => {
    const {
      localities = [],
      state = "",
      municipality = "",
    } = await getGeolocationData(value, data.country);
    setSuburbs(localities);
    setAllData({
      ...data,
      state,
      municipality,
      suburb: localities.length === 1 ? localities[0].value : data.suburb,
    });
  };

  const handlePhoneChange = async (e) => {
    const { value, name } = e.target;
    onDataChange({ target: { name, value: formatPhone(value) } });
  };

  useEffect(() => {
    handleZipChange(data.postalCode);
  }, [data.postalCode]);

  // Requests
  const submitData = async (e) => {
    e.preventDefault();
    const res = await Request("/client", { ...data }, "POST");
    if (res.ok) {
      Swal.fire("Registro existoso", "", "success").then(() => {
        navigate("/clientes");
      });
    }
  };

  return (
    <Skinpage pageTitle={"Alta de Cliente"}>
      <DgForm
        data={data}
        onChange={onDataChange}
        onSubmit={submitData}
        groups={clientInputs(
          data,
          { handlePhoneChange },
          { CatRegime, CatCfdi, CatPaymentWay, suburbs }
        )}
      >
        <div className="grid grid-cols-3">
          <div />
          <div />
          <Button type="submit">Continuar</Button>
        </div>
      </DgForm>
    </Skinpage>
  );
};

export default NewClient;
