import React from "react";
import "../App.css";
import "../App.jsx";
import { Bell, User, Settings } from "lucide-react";

const TopBar = ({ pageTitle = "" }) => {
  return (
    <div className="topBar w-full bg-sesInputBG h-16 z-10">
      <div className="pageTitle">{pageTitle}</div>
      <div className="w-1/4 relative float-right right-0" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginRight: "2rem" }}>
        <button className="bg-sesBlue w-10 h-10 border-none relative float-left mx-2 rounded-full">
          <Bell color="white" size={28} className="m-auto" />
        </button>
        <button className="bg-sesBlue w-10 h-10 border-none relative float-left mx-2 rounded-full">
          <User color="white" size={28} className="m-auto" />
        </button>
        <div className="relative float-left" style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h3 className="cssUserName text-base text-sesGray font-semibold">Willie Jennings</h3>
            <Settings color="#565656" size={20} />
          </div>
          <p className="cssUserRole font-regular text-sm text-sesGray">Director</p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
