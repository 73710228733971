import * as icons from "lucide-react";
import "../App.css";
import "../App.jsx";

const InputIcon = ({ name, color, size }) => {
  const LucideIcon = icons[name];

  return (
    <LucideIcon
      color={color}
      size={size}
      className="iconInput ml-2 mr-4 absolute z-10"
    />
  );
};

export default InputIcon;
