import React, { useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import Button from "../../components/Button";
import DgInput from "../../components/DgInput";
import { useForm } from "../../helpers/useForm";
import { Request } from "../../helpers/api";
import Swal from "sweetalert2";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ClientSendEmail = ({ clientData }) => {
  const [data, setData, clearData, setAllData] = useForm({
    subject: "",
    body: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { body, subject } = data;
    const res = await Request(
      "/client/" + clientData.idClient + "/mail",
      { subject, body: draftToHtml(convertToRaw(body.getCurrentContent())) },
      "POST"
    );
    if (res.ok) {
      Swal.fire("Email enviado", "", "success").then(() => {
        clearData();
      });
    }
  };
  return (
    <div className="w-full m-auto">
      <div className="titulosDetalle" style={{ marginBottom: "1rem" }}>
        NUEVO EMAIL
      </div>
      <form onSubmit={handleSubmit}>
        <DgInput
          type="Text"
          maxLengh="75"
          name="subject"
          label="Asunto del correo"
          placeholder="Recordatorio de cobranza ..."
          required={true}
          iconName="Mail"
          onChange={setData}
          value={data.subject}
        />
        <Editor
          editorState={data.body}
          toolbarClassName="toolbarClassName"
          wrapperStyle={{
            boxShadow: "0px 2px 3px rgb(0 0 0 / 20%)",
            marginTop: "10px",
          }}
          editorStyle={{
            padding: "10px 15px",
            height: "200px",
            background: "#f9f9f9",
            border: "1px solid #d2d2d2",
          }}
          onEditorStateChange={(text) => {
            setData({ target: { value: text, name: "body" } });
          }}
        />
        <div className="grid grid-cols-3">
          <div />
          <div />
          <Button type="submit"> Enviar </Button>
        </div>
      </form>
    </div>
  );
};

ClientSendEmail.propTypes = {};

export default ClientSendEmail;
