import React, { useCallback, useEffect, useState } from "react";
import iconoPlantillaServices from "../../css/img/IconoPlantillaServicio.svg";
import { Edit, PlusSquare } from "lucide-react";
import DgForm from "../../components/DgForm";
import DgButton from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import { conceptSchema, serviceSchema } from "../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { getGeolocationData, parseCat } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../components/ButtonIcon";
import Swal from "sweetalert2";
import { Request } from "../../helpers/api";
import "bootstrap/dist/css/bootstrap.min.css";
import { addressInputs } from "../../helpers/formUtils";

const ModalPlantillaServices = ({
  isOpen,
  toggle,
  onConfirm = () => {},
  singleData = serviceSchema,
}) => {
  const [fiscalPeriodList, setFiscalPeriodList] = useState([
    { idFiscalPeriod: "", name: "" },
  ]);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...serviceSchema,
  });
  const { serviceConcepts: catConcepts, catRegions: regions } = useSelector(
    (s) => s.catalogues
  );
  const [suburbs, setSuburbs] = useState([]);

  const handleZipChange = async (value) => {
    const {
      localities = [],
      state: stateService = "",
      municipality: municipalityService = "",
    } = await getGeolocationData(value, data.countryService);
    setSuburbs(localities);
    setAllData({
      ...data,
      stateService,
      municipalityService,
      suburbService:
        localities.length === 1 ? localities[0].value : data.suburbService,
    });
  };

  const handleNestedChange = (e, ix) => {
    const { value, name } = e.target;
    const arr = data.serviceConcepts;
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    console.log(item);
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };
  const handleNestedItems = (willDelete = false, ix = null) => {
    const arr = data.serviceConcepts;
    if (willDelete) {
      arr.splice(ix, 1);
    } else {
      arr.push({ ...conceptSchema });
    }
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request(
      "/service/servicetemplate/" + singleData.idService,
      data,
      "PUT"
    );
    if (res.ok) {
      Swal.fire("Modificación de plantilla exitosa", "", "success").then(
        () => {}
      );
    }
  };
  const fetchFiscalPeriods = useCallback(async () => {
    const res = await Request(`/user/fiscalPeriods?limit=4&all=false`);
    if (res.ok) {
      setFiscalPeriodList(res.data);
    }
  }, []);
  const getFields = () => {
    const getConceptFields = () => {
      let fields = [];
      data.serviceConcepts.forEach((sc, ix) => {
        const onNestedChange = (e) => {
          handleNestedChange(e, ix);
        };
        fields = fields.concat([
          {
            label: "Concepto de factura",
            placeholder: "Concepto de factura",
            type: "select",
            options: [
              { value: "", label: "Elige un concepto" },
              ...parseCat(catConcepts, "name", "idCatServiceConcept"),
            ],
            name: "idCatServiceConcept",
            onInputChange: onNestedChange,
            required: true,
            iconName: "Files",
            containerClassName: "mt-0",
            value: sc.idCatServiceConcept,
            errorMessage: "Éste concepto de factura no es válido",
          },
          {
            label: "Cantidad",
            placeholder: "2",
            minLength: "1",
            maxLength: "2",
            type: "number",
            name: "quantity",
            onInputChange: onNestedChange,
            required: false,
            iconName: "Hash",
            containerClassName: "mt-0",
            value: sc.quantity,
            errorMessage: "Indique una cantidad en el rango 1 a 99",
          },
          {
            label: "Precio base individual",
            type: "number",
            name: "price",
            step: 0.1,
            min: 0.1,
            required: true,
            onInputChange: onNestedChange,
            value: sc.price,
            iconName: "DollarSign",
            containerClassName: "mt-0",
          },
          {
            label: "Descripción",
            type: "text",
            name: "description",
            required: false,
            onInputChange: onNestedChange,
            value: sc.description,
            iconName: "Outdent",
            containerClassName: "mt-0",
          },
          {
            element: (
              <ButtonIcon
                className="text-sesRed  px-1"
                onClick={() => handleNestedItems(true, ix)}
                icon={faMinusSquare}
              />
            ),
          },
        ]);
      });
      return fields;
    };
    return [
      {
        fields: [
          {
            label: "Nombre comercial del servicio",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.name,
            minLength: "5",
            required: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Periodicidad del pago",
            placeholder: "Periodicidad del pago",
            type: "select",
            name: "paymentPeriod",
            value: data.paymentPeriod,
            options: [
              { label: "Selecciona un elemento", value: "" },
              { label: "Pago Quincenal", value: "BIWEEKLY" },
              { label: "Mensual", value: "MONTHLY" },
            ],
            required: true,
            containerClassName: "mt-0",
            iconName: "DollarSign",
            errorMessage: "Seleccione una opción",
          },
          // {
          //   label: "Periodo inicial",
          //   placeholder: "Periodo inicial",
          //   type: "select",
          //   name: "idFiscalPeriod",
          //   value: data.idFiscalPeriod,
          //   required: true,
          //   options: [
          //     { value: "", label: "Selecciona un elemento" },
          //     ...parseCat(fiscalPeriodList, "name", "idFiscalPeriod"),
          //   ],
          //   iconName: "Calendar",
          //   containerClassName: "mt-0",
          //   errorMessage: "Seleccione una opción",
          // },
        ],
      },
      {
        title: "CONCEPTOS",
        withAdd: true,
        addElement: (
          <div
            onClick={() => {
              handleNestedItems();
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        ),
        groupClassNameGrid: "grid-cols-3 conceptsList",
        fields: getConceptFields(),
      },
      {
        title: "DOMICILIO",
        fields: [
          ...addressInputs(data, suburbs, "Service"),
          {
            label: "Región",
            placeholder: "Región",
            type: "select",
            name: "idCatRegion",
            required: true,
            value: data.idCatRegion,
            options: parseCat(regions, "name", "idCatRegion"),
            iconName: "MapPin",
            containerClassName: "mt-0",
          },
        ],
      },
    ];
  };
  const fetchData = useCallback(async (idService) => {
    const res = await Request("/service/servicetemplate/" + idService);
    if (res.ok) {
      setAllData(res.data);
    }
  }, []);
  useEffect(() => {
    handleZipChange(data.postalCodeService);
  }, [data.postalCodeService]);
  useEffect(() => {
    if (singleData.idService) {
      fetchData(singleData.idService);
    }
  }, [singleData]);
  useEffect(() => {
    fetchFiscalPeriods();
  }, []);
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <div
          style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
        >
          PLANTILLA DE SERVICIO
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={iconoPlantillaServices} style={{ width: "5rem" }} />
            <div
              style={{
                marginLeft: "1rem",
                fontWeight: 600,
                color: "#2A2F99",
              }}
            >
              COBERTURA DE EVENTO
            </div>
          </div>
          <div>
            <Edit></Edit>
          </div>
        </div>
        <DgForm
          data={data}
          classNameGrid="grid grid-cols-2 gap-4"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <ModalFooter>
            <div className="grid grid-cols-3 gap-5">
              <div />
              <DgButton type="submit" color="primary" onClick={onConfirm}>
                Actualizar
              </DgButton>
              <DgButton onClick={toggle}>Cerrar</DgButton>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
    </Modal>
  );
};
export default ModalPlantillaServices;
