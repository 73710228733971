import React, { useState } from "react";
import DgInput from "../../components/DgInput";
import { Wallet, Edit, PlusSquare } from "lucide-react";
import ModalPlantillaServices from "../modals/ModalPlantillaServices";

const ListServices = (props) => {
  const { clickNewService, clientServices } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [services, setServices] = useState(clientServices);
  const [servicesAll] = useState(clientServices);
  const [valSearch, setValSearch] = useState("");
  const [serviceAct, setServiceAct] = useState({});

  const handleSearch = (e) => {
    const valS = e.target.value;
    setValSearch(valS);
    if (valS != "") {
      let serviceAux = servicesAll.filter((serv) => {
        return serv.name?.toLowerCase().includes(valS.toLowerCase());
      });
      setServices(serviceAux);
    } else {
      setServices(servicesAll);
    }
  };

  return (
    <>
      <div style={{ width: "50%" }}>
        <div className="titulosDetalle">Lista de Servicios</div>
        <DgInput
          placeholder="Buscar Servicio"
          iconName="Search"
          containerClassName="mt-0"
          onChange={handleSearch}
          value={valSearch}
        />

        {services.map((serv) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "lavender",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "0.5rem",
              }}
            >
              <div
                style={{
                  height: "7.5rem",
                  width: "1rem",
                  backgroundColor: "#2A2F99",
                  opacity: 0.5,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1rem",
                  padding: "0.6rem 0rem",
                  width: "25rem",
                }}
              >
                <div style={{ color: "#2A2F99" }}>{serv.name}</div>
                <div style={{ color: "grey" }}>{serv.paymentPeriod}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0rem 1rem",
                }}
              >
                <Edit
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleOpen();
                    setServiceAct(serv);
                  }}
                />
                &nbsp;
                {/*<Wallet/>*/}
              </div>
            </div>
          );
        })}
        <div
          onClick={clickNewService}
          style={{
            color: "#2A2F99",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <PlusSquare fill="#2A2F99" color="white" size={35} />
          Añadir otro servicio
        </div>
      </div>
      <ModalPlantillaServices
        toggle={() => {
          setOpen(!open);
        }}
        isOpen={open}
        onClose={handleClose}
        singleData={serviceAct}
      />
    </>
  );
};

export default ListServices;
