import React from "react";
import PropTypes from "prop-types";
import TopBar from "./TopBar";
import Menu from "./Menu";

const Skinpage = ({ children, pageTitle }) => {
  return (
    <div>
      <div className="container">
        <TopBar pageTitle={pageTitle} />
        <Menu />
      </div>
      <div className="container mt-20 mb-10">{children}</div>
    </div>
  );
};

Skinpage.propTypes = {
  children: PropTypes.element,
};

export default Skinpage;
