import React, { useEffect, useState } from "react";
import { Eye } from "lucide-react";
import { commentSchema } from "../../helpers/schemas";
import Swal from "sweetalert2";
import { Request } from "../../helpers/api";
import { useParams } from "react-router-dom";
import DgForm from "../../components/DgForm";
import Button from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import { parseObjectDate } from "../../helpers/utils";

const Comments = ({ clientData = {}, setClientAllData }) => {
  const { id: idClient } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...commentSchema,
  });
  useEffect(() => {
    setAllData(clientData);
  }, []);
  const getFields = () => [
    {
      fields: [
        {
          label: "Título",
          placeholder: "Título",
          type: "text",
          name: "title",
          // value: data.title,
          onInputChange: onDataChange,
          minLength: "1",
          required: true,
          iconName: "FormInput",
          errorMessage: "Este título no es válido",
        },
        {
          label: "Comentarios",
          placeholder: "Escriba aqui...",
          type: "textarea",
          name: "description",
          // value: data.description,
          onInputChange: onDataChange,
          required: true,
          iconName: "MessageCircle",
          errorMessage: "Un comentario es requerido.",
        },
      ],
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request(
      `/client${"/" + idClient}/comment`,
      { ...data, idClient },
      "POST"
    );
    setClientAllData({ ...clientData, ...res.data });
    if (res.ok) {
      Swal.fire("El comentario a ha añadido existosamente", "", "success").then(
        () => {
          window.location.reload();
        }
      );
    }
  };
  return (
    <>
      <div className="titulosDetalle" style={{ marginBottom: "1rem" }}>
        Comentarios
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "45%" }}>
          <DgForm
            data={data}
            classNameGrid="grid grid-cols-1"
            onDataChange={onDataChange}
            onSubmit={handleSubmit}
            groups={getFields()}
          >
            {/* <div className="grid grid-cols-1">
              <div type="submit" style={{ color: "#2A2F99", display: "flex", alignItems: "center", cursor: "pointer", margin: "1rem 0rem" }}>
                <PlusSquare fill="#2A2F99" color="white" size={35}></PlusSquare>
                Nuevo comentario
              </div>
            </div> */}
            <Button type="submit">Añadir Comentario</Button>
          </DgForm>
        </div>
        <div style={{ width: "5%" }}></div>
        <div style={{ width: "50%" }}>
          <div
            style={{
              marginTop: "2rem",
              color: "#2A2F99",
              fontWeight: 600,
              margin: "1rem 0rem",
            }}
          >
            LISTA DE COMENTARIOS
          </div>
          {clientData.comments?.map((sc) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "lavender",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "0.5rem",
                }}
              >
                <div
                  style={{
                    height: "6rem",
                    width: "1rem",
                    backgroundColor: "#2A2F99",
                    opacity: 0.5,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "1rem",
                    padding: "0.6rem 0rem",
                    width: "25rem",
                  }}
                >
                  <div style={{ color: "#2A2F99" }}>{sc.title}</div>
                  <div style={{ color: "grey" }}>
                    {sc.userName} - {parseObjectDate(new Date(sc.createdAt))}
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontStyle: "italic",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {sc.description}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "0rem 1rem",
                  }}
                >
                  <Eye />
                </div>
              </div>
            );
          })}
          {clientData.comments?.length === 0 && <div>Sin Comentarios</div>}
        </div>
      </div>
    </>
  );
};

export default Comments;
