import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/Button";
import { downloadBlobFile } from "../../helpers/utils";
import { Request } from "../../helpers/api";

const Payments = () => {
  const handleSubmit = async () => {
    const res = await Request(
      "/report/paymentRelation/",
      null,
      "GET",
      true,
      false,
      true
    );
    if (res) {
      downloadBlobFile(res, "Relación de pagos.xlsx");
    }
  };
  return (
    <div>
      <div className="grid gap-3" style={{ gridTemplateColumns: "80% 20%" }}>
        <div />
        <Button style={{ marginTop: "40px" }} onClick={handleSubmit}>
          Generar Reporte
        </Button>
      </div>
    </div>
  );
};

Payments.propTypes = {};

export default Payments;
