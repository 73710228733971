import Swal from "sweetalert2";
import { endSession, getFromSession } from "./storageHandler";
const BASE_URL = process.env.REACT_APP_API_URL;

export const Request = async (
  path,
  body = {},
  method = "GET",
  isInternal = true,
  isFormdata = false,
  isResponseBlob = false
) => {
  const headers = {
    Accept: "application/json",
    "x-auth": getFromSession("jwt"),
    ...(isFormdata
      ? {}
      : {
          "Content-Type": "application/json",
        }),
  };
  let response = { ok: false };
  try {
    const reqResponse = await fetch((isInternal ? BASE_URL : "") + path, {
      method,
      headers,
      body: ["GET", "DELETE"].includes(method)
        ? null
        : isFormdata
        ? body
        : JSON.stringify(body),
    });
    if (reqResponse.ok) {
      response = isResponseBlob
        ? await reqResponse.blob()
        : await reqResponse.json();
    } else {
      const errResponse = await reqResponse.json();
      switch (reqResponse.status) {
        case 400:
          Swal.fire({
            title: "Ocurrió un error",
            text: errResponse.error || errResponse.msg,
            icon: "error",
          });
          break;
        case 401:
          Swal.fire({
            title: "Ops",
            text: "La sesión ha expirado, debes iniciar sesión nuevamente",
            icon: "warning",
          }).then(() => {
            endSession();
          });
          break;
        default:
          Swal.fire({
            title: "Ocurrió un error",
            text: errResponse.error || errResponse.msg,
            icon: "error",
          });
          break;
      }
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Ocurrió un error",
      text: error,
    });
  }
  return response;
};
