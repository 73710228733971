import React, { useEffect, useState } from "react";
import DgForm from "../../components/DgForm";
import { useForm } from "../../helpers/useForm";
import Button from "../../components/Button";
import { clientSchema } from "../../helpers/schemas";
import Swal from "sweetalert2";
import { Request } from "../../helpers/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientInputs } from "../../helpers/formUtils";
import { getGeolocationData } from "../../helpers/utils";

const DetailClientInfo = ({ clientData, setClientAllData }) => {
  const { id: idClient } = useParams();
  const [suburbs, setSuburbs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { CatCfdi, CatRegime, CatPaymentWay } = useSelector(
    (s) => s.catalogues
  );
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientData,
  });

  const editClient = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(`/client/${idClient}`, data, "PUT");
    setIsLoading(false);
    if (res.ok) {
      Swal.fire({
        title: "Actualización exitosa",
        icon: "success",
      }).then(() => {
        setClientAllData({ ...clientData, ...res.data });
      });
    }
  };

  useEffect(() => {
    setAllData(clientData);
  }, []);

  const handlePhoneChange = async (e) => {
    const { value, name } = e.target;
    const clearValue = value.replaceAll("-", "");
    const formattedValue =
      clearValue.length === 10
        ? [
            clearValue.substring(0, 2),
            clearValue.substring(2, 6),
            clearValue.substring(6, 10),
          ].join("-")
        : value;
    onDataChange({ target: { name, value: formattedValue } });
  };

  const handleZipChange = async (value) => {
    const {
      localities = [],
      state = "",
      municipality = "",
    } = await getGeolocationData(value, data.country);
    setSuburbs(localities);
    setAllData({
      ...data,
      state,
      municipality,
      suburb: localities.length === 1 ? localities[0].value : data.suburb,
    });
  };

  useEffect(() => {
    handleZipChange(data.postalCode);
  }, [data.postalCode]);

  return (
    <>
      <div className="titulosDetalle">Editar Datos de Cliente</div>
      <DgForm
        data={data}
        classNameGrid="grid grid-cols-2 gap-4"
        onChange={onDataChange}
        onSubmit={editClient}
        groups={clientInputs(
          data,
          { handlePhoneChange },
          { CatRegime, CatCfdi, CatPaymentWay, suburbs }
        )}
      >
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            Actualizar
          </Button>
        </div>
      </DgForm>
    </>
  );
};

export default DetailClientInfo;
