import municipalitiesCat from "./statesMunicipalities.json";
import { parseCat } from "./utils";

export const addressInputs = (data, suburbs, fieldPostfix = "") => {
  const municipalities = (
    municipalitiesCat[data["state" + fieldPostfix]] || []
  ).map((m) => ({
    value: m,
    label: m,
  }));
  return [
    {
      label: "Calle",
      placeholder: "Av General",
      type: "text",
      name: "street" + fieldPostfix,
      maxLength: "150",
      minLength: "1",
      required: data.isProsecutor,
      iconName: "FormInput",
    },
    {
      label: "Código postal",
      placeholder: "00000",
      type: "text",
      maxLength: 6,
      pattern: "^[0-9]{0-6}",
      name: "postalCode" + fieldPostfix,
      required: data.isProsecutor,
      iconName: "FormInput",
    },
    {
      label: "Núm Ext",
      placeholder: "123",
      type: "text",
      name: "outdoorNumber" + fieldPostfix,
      maxLength: "20",
      minLength: "1",
      required: data.isProsecutor,
      iconName: "FormInput",
    },
    {
      label: "Núm Int",
      placeholder: "",
      type: "text",
      name: "internalNumber" + fieldPostfix,
      maxLength: "20",
      minLength: "1",
      required: false,
      iconName: "FormInput",
    },
    {
      label: "Colonia",
      placeholder: "-",
      type: suburbs?.length > 0 ? "select" : "text",
      name: "suburb" + fieldPostfix,
      options: [{ label: "Elige una colonia", value: "" }, ...suburbs],
      required: data.isProsecutor,
      disabled: data.postalCode === "",
      iconName: "FormInput",
    },
    {
      label: "Municipio",
      placeholder: "-",
      type: municipalities?.length > 0 ? "select" : "text",
      name: "municipality" + fieldPostfix,
      options: [{ label: "Elige un municipio", value: "" }, ...municipalities],
      required: data.isProsecutor,
      disabled: data.postalCode === "",
      iconName: "FormInput",
    },
    {
      label: "Estado",
      placeholder: "-",
      type: "text",
      name: "state" + fieldPostfix,
      required: data.isProsecutor,
      disabled: true,
      iconName: "FormInput",
    },
    {
      label: "País",
      type: "select",
      options: [
        {
          label: "México",
          value: "MX",
        },
      ],
      value: "MX",
      name: "country" + fieldPostfix,
      required: data.isProsecutor,
      disabled: true,
      iconName: "FormInput",
    },
  ];
};

export const clientInputs = (data, handlers, catalogues) => {
  const { handlePhoneChange, onInputChange = null } = handlers;
  const { suburbs, CatCfdi, CatPaymentWay, CatRegime } = catalogues;
  console.log(data);
  return [
    {
      title: "DATOS DE CLIENTE",
      fields: [
        {
          type: "radio",
          name: "clientType",
          options: [
            { value: "FISCAL", label: "Cliente fiscal" },
            { value: "NONFISCAL", label: "Cliente no fiscal" },
          ],
          value: data.clientType,
          onInputChange,
          containerClassName: "col-span-2",
        },
        {
          label: "Razón social",
          placeholder: "Seguritas S.A. de C.V",
          type: "text",
          name: "name",
          onInputChange,
          minLength: "2",
          required: true,
          iconName: "FormInput",
          errorMessage: "Ésta razón social no es válida",
        },
        {
          label: "RFC",
          placeholder: "SRTAS2S1S",
          type: "text",
          maxLength: 13,
          minLength: 12,
          name: "rfc",
          onInputChange,
          required: data.isProsecutor,
          iconName: "FormInput",
        },
        {
          label: "Teléfono",
          placeholder: "XX-XXXX-XXXX",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          minLength: "12",
          maxLength: "12",
          type: "tel",
          name: "phone",
          onInputChange: handlePhoneChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato 55-5555-5555",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "email",
          onInputChange,
          required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DATOS DEL ADMINISTRADOR",
      fields: [
        {
          label: "Nombre",
          placeholder: "Juan Pérez",
          type: "text",
          name: "administratorName",
          onInputChange,
          maxLength: "150",
          minLength: "2",
          //required: true,
          iconName: "FormInput",
        },
        {
          label: "Teléfono",
          placeholder: "XX-XXXX-XXXX",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          minLength: "12",
          maxLength: "12",
          type: "tel",
          name: "administratorPhone",
          onInputChange: handlePhoneChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato 55-5555-5555",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          onInputChange,
          name: "administratorEmail",
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DATOS DEL CONTADOR O RESPONSABLE DE PAGO",
      fields: [
        {
          label: "Nombre",
          placeholder: "Juan Pérez",
          type: "text",
          name: "counterName",
          onInputChange,
          maxLength: "150",
          minLength: "2",
          //required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico SES",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "counterEmail",
          onInputChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DOMICILIO FISCAL",
      fields: addressInputs(data, suburbs),
    },
    {
      title: "FACTURACIÓN",
      fields: [
        {
          label: "Uso de CFDI",
          type: "select",
          name: "idCatCfdi",
          onInputChange,
          required: data.isProsecutor,
          options: [
            { value: "", label: "Selecciona un elemento" },
            ...parseCat(CatCfdi, "name", "idCatCfdi"),
          ],
          iconName: "FormInput",
        },
        {
          label: "Método de pago",
          type: "select",
          name: "idCatPaymentWay",
          onInputChange,
          required: data.isProsecutor,
          options: [
            { value: "", label: "Selecciona un elemento" },
            ...parseCat(CatPaymentWay, "name", "idCatPaymentWay"),
          ],
          iconName: "FormInput",
        },
        {
          label: "Régimen",
          type: "select",
          name: "idCatRegime",
          onInputChange,
          required: data.isProsecutor,
          options: [
            { value: "", label: "Selecciona un elemento" },
            ...parseCat(CatRegime, "name", "idCatRegime"),
          ],
          iconName: "FormInput",
        },
      ],
    },
  ];
};
